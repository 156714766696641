import { Card } from '../components/Card';
import { useRecoilState } from 'recoil';
import { Shape, shape } from '../models';
import styled from 'styled-components';
import { PageTitle } from '../components/PageTitle';
import abstract from '../assets/patterns/abstract.png';
import canvas from '../assets/patterns/canvas.png';

export const ShapeSelection = () => {
  const [selectedShape, setShape] = useRecoilState(shape);

  const createHandleClick = (shape: Shape) => () => {
    setShape(shape);
  };

  const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;
  `;

  return (
    <div>
      <PageTitle text="Choose Your Visual Style" />
      <Wrapper>
        <Card
          selected={selectedShape === 'free'}
          title="Auto Generated"
          image={abstract}
          onClick={createHandleClick('free')}
          width='400px'
          height='350px'
          imageStyle={{
            height: 210,
            width: 205,
          }}
          paddingTop={'24px'}
          wrapperStyles={{
            display: 'flex',
            justifyContent: 'center',
          }}
        />
        <Card
          selected={selectedShape === 'custom'}
          title="Custom assets (NFT)"
          image={canvas}
          onClick={createHandleClick('custom')}
          width='400px'
          height='350px'
          imageStyle={{
            height: 210,
            width: 205,
          }}
          paddingTop={'24px'}
          wrapperStyles={{
            display: 'flex',
            justifyContent: 'center',
          }}
        />
      </Wrapper>
    </div>
  );
};
