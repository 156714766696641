import { useRecoilState, useRecoilValue } from 'recoil';

import { NameForm } from './NameForm';
import { applicationView, category, isCompanyNameValid, logoStep, shape } from '../models';
import { ShapeSelection } from './ShapeSelection';
import { CategorySelection } from './CategorySelection';
import { Preview } from './Preview';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: calc(100vh - 160px);
`;

export const LogoEditor = () => {
  const [step, setStep] = useRecoilState(logoStep);
  const isNameValid = useRecoilValue(isCompanyNameValid);
  const selectedShape = useRecoilValue(shape);
  const selectedCategory = useRecoilValue(category);

  const createHandleStepBack = () => {
    switch (step) {
      case 'shapes':
        return () => setStep('name');
      case 'category':
        return () => setStep('shapes');
      case 'results':
        return () => setStep(selectedShape === 'custom' ? 'category' : 'shapes');
    }
  };

  const handleClickNext = () => {
    switch (step) {
      case 'name':
        setStep('shapes');
        break;
      case 'shapes':
        setStep(selectedShape === 'custom' ? 'category' : 'results');
        break;
      case 'category':
        setStep('results');
        break;
      default:
        break;
    }
  };

  const isNextDisabled = () => {
    switch (step) {
      case 'name':
        return !isNameValid;
      case 'shapes':
        return selectedShape === undefined;
      case 'category':
        return selectedCategory === undefined;
      default:
        return undefined;
    }
  };

  return (
    <Content>
      <Header />
      <Wrapper>
        {step === 'name' && <NameForm />}
        {step === 'shapes' && <ShapeSelection />}
        {step === 'category' && <CategorySelection />}
        {step === 'results' && <Preview />}
      </Wrapper>
      <Footer
        onBack={createHandleStepBack()}
        onNext={handleClickNext}
        nextDisabled={isNextDisabled()}
      />
    </Content>
  );
};
