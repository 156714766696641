import { useRecoilState, useRecoilValue } from 'recoil';

import { logoStep, companyName, isCompanyNameValid } from '../models';
import { Input } from '../components/Input';
import styled from 'styled-components';
import { PageTitle } from '../components/PageTitle';
import cover from './name-page-cover.jpg'

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-image: url(${cover});
  background-size: cover;
  background-position: center;
`;

const Wrapper = styled.div`
  height: 300px;
  width: 100%;
`;

export const NameForm = () => {
  const [name, setName] = useRecoilState(companyName);
  const [, setStep] = useRecoilState(logoStep);
  const isValid = useRecoilValue(isCompanyNameValid);

  const handleSubmit = () => {
    if (isValid) {
      setStep('shapes');
    }
  };

  return (
    <Content>
      <Wrapper>
        <PageTitle text="Create Amazing Logos with PicsArt in Seconds" />
        <Input
          name="company"
          placeholder="Enter your logo name"
          value={name}
          onChange={e => setName((e.target as HTMLInputElement).value)}
          onKeyUp={e => e.key === 'Enter' && handleSubmit()}
        />
      </Wrapper>
    </Content>
  );
};
