import { FC } from 'react';
import styled from 'styled-components';

const Heading = styled.h1`
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 70px;
  text-align: center;
  color: #6c7080;
  max-width: 690px;
`;

export const PageTitle: FC<{ text?: string }> = ({ text }) =>
  text ? <Heading>{text}</Heading> : null;
