import { FC, FunctionComponent } from 'react';
import styled from 'styled-components';

type CardProps = {
  title: string;
  icon?: JSX.Element;
  onClick: () => void;
  selected?: boolean;
  image?: string;
  width?: string;
  height?: string;
  imageStyle?: any;
  paddingTop?: string;
  wrapperStyles?: any;
};

const Wrapper = styled.button<any>`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => width ? width : '300px'};
  height: ${({ height }) => height ? height : '260px'};
  box-sizing: border-box;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
  border-style: solid;
  border-width: 3px;
  border-color: ${props => (props.selected ? '#3A76E8' : 'transparent')};
  padding: 40px 70px 60px;
  transition: all ease-in 0.2s;
  cursor: ${props => (props.selected ? 'default' : 'pointer')};

  &:hover {
    border-color: ${props => (props.selected ? '#3A76E8' : '#dcdde9')};
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
  }
`;

const Img = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Text = styled.div<any>`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #636369;
  padding-top: ${({ paddingTop }) => paddingTop}
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
  max-width: 1300px;
  margin: 0 auto;
`;

export const Card: FC<CardProps> = ({ icon, image, title, selected, onClick, width, height, imageStyle = {}, paddingTop = '0px', wrapperStyles = {} }: CardProps) => {
  return (
    <Wrapper selected={selected} onClick={onClick} width={width} height={height}>
      <div style={wrapperStyles}>
        { image ? <Img src={image} style={imageStyle}/> : icon}
      </div>
      <Text paddingTop={paddingTop}>{title}</Text>
    </Wrapper>
  );
};
