import { useRecoilState, useRecoilValue } from 'recoil';
import { colors } from '../constants';
import { companyName, shape, category, palette } from '../models';

export const Preview = () => {
  const name = useRecoilValue(companyName);
  const selectedShape = useRecoilValue(shape);
  const selectedCategory = useRecoilValue(category);
  const [selectedPalette, setSelectedPalette] = useRecoilState(palette);

  return (
    <div>
      {/*{colors.map(item => (*/}
      {/*  <>*/}
      {/*    <ColorPalette value={item} onClick={value => setSelectedPalette(value)} />*/}
      {/*    <br />*/}
      {/*  </>*/}
      {/*))}*/}
      <br />
      <div>
        {`name: ${name}`}
        <br />
        {`${
          selectedShape === 'custom' ? `category: ${selectedCategory}` : `shape: ${selectedShape}`
        }`}
        <br />
        {`colors: ${selectedPalette}`}
      </div>
    </div>
  );
};
