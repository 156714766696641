import {Card, CardsWrapper} from '../components/Card';
import { useRecoilState } from 'recoil';
import { Category, category } from '../models';
import { PageTitle } from '../components/PageTitle';
import sportIcon from '../assets/patterns/sport_4.png';
import foodIcon from '../assets/patterns/Bakery  Pastry - Breakfast 2 1.png'
import businessIcon from '../assets/patterns/business_law_banking_15.svg';

export const CategorySelection = () => {
  const [selectedCategory, setCategory] = useRecoilState(category);

  const createHandleClick = (category: Category) => () => {
    setCategory(category);
  };

  return (
    <div>
      <PageTitle text="Choose Your Category" />
      <CardsWrapper>
        <Card
          selected={selectedCategory === 'food'}
          onClick={createHandleClick('food')}
          image={foodIcon}
          title="Food"
        />
        <Card
          selected={selectedCategory === 'banking'}
          onClick={createHandleClick('banking')}
          image={businessIcon}
          imageStyle={{ height: 145 }}
          title="Banking"
        />
        <Card
          selected={selectedCategory === 'sport'}
          onClick={createHandleClick('sport')}
          image={sportIcon}
          title="Sport"
        />
        {/*<Card*/}
        {/*  selected={selectedCategory === 'hackathon'}*/}
        {/*  onClick={createHandleClick('hackathon')}*/}
        {/*  image={hackathon}*/}
        {/*  title="Hackathon"*/}
        {/*/>*/}
      </CardsWrapper>
    </div>
  );
};
