import { Color, PatternCategory, StickerCategory } from '../models';
import Axios from 'axios';
import { nanoid } from 'nanoid';
import { stickers } from '../constants';

const getRandomFromRange = (min: number, max: number): number => {
  const diff = max - min;
  return min + Math.random() * diff;
};

export const getPatternBg = async (pattern: PatternCategory, style: number, colors: Color[]) => {
  const params = {
    input_id: nanoid(),
    patterns_count: 5,
    pattern_type: pattern.toUpperCase(),
    style_type: `Style${style}`,
    colors: colors.flat().join(',')
  };

  const headers = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9'
  };

  const req = async () =>
    Axios.get('https://ai.picsart.com/bg/pattern-distributor/', {
      params,
      headers
    })
      .then(res => res.data.data.url)
      .catch(() => '');

  return Promise.all([req(), req(), req(), req(), req(), req(), req(), req(), req()]);
};

export const getArrangeStickers = async (category: StickerCategory, variant: number) => {
  const headers = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9'
  };

  const req = async (sizeScale: number = 2, withPadding = true) => {
    const res: any = await Axios.post(
      `https://ai.picsart.com/bg/sticker-arrangement/`,
      {
        canvas: {
          width: 1170,
          height: 1024
        },
        render_format: 'svg',
        average_object_size: 45 * sizeScale,
        arrangeable_objects: stickers[category][variant].map(item => ({
          name: nanoid(),
          image: {
            image_id: nanoid(),
            image_url: item
          },
          padding: withPadding ? getRandomFromRange(0.1, 0.2) : 0,
          scale_variance: getRandomFromRange(0.1, 0.3)
        }))
      },
      { headers }
    ).catch(() => '');

    const url = res.data.data.url;
    const svgRes = await Axios.get(url);

    return svgRes.data.replace('height="1080" width="1920" ', 'viewbox="0 0 1920 1080"');
  };

  const result = [];

  const getBomb = () =>
    Promise.all([req(1, false), req(1, false), req(1, false), req(2), req(2), req(3)]).then(data =>
      data.join('')
    );

  result.push(req());
  result.push(getBomb());
  result.push(req());
  result.push(req());
  result.push(getBomb());
  result.push(getBomb());
  result.push(getBomb());
  result.push(req());
  result.push(getBomb());

  // return Promise.all([req(), req(), req(), req(), req(), req(), req(), req(), req()]);
  return result;
};
