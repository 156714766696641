import styled from 'styled-components';
import { ReactElement } from 'react';

type PaletteItemProps<T = any> = {
  value: T;
  separated?: boolean;
};

type PaletteProps = {
  children: any;
  selected?: boolean;
};

// type ColorPaletteItemProps = PaletteItemProps<[number, number, number]>;
// type PatternPaletteItemProps = PaletteItemProps<string>;

export const Palette = styled.div<PaletteProps>`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 3px;
  border-color: ${props => (props.selected ? '#3A76E8' : 'transparent')};
  transition: all ease-in 0.2s;
  cursor: ${props => (props.selected ? 'default' : 'pointer')};

  &:hover {
    border-color: ${props => (props.selected ? '#3A76E8' : '#dcdde9')};
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
  }
`;

export const ColorPaletteItem = styled.button<PaletteItemProps>`
  all: unset;
  display: block;
  width: 64px;
  height: 74px;
  background: rgb(${props => props.value.join(',')});
  border-left: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  border-right: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  cursor: pointer;

  &:first-child {
    border-left: 0 solid #fff;
  }

  &:last-child {
    border-right: 0 solid #fff;
  }
`;

export const PatternPaletteItem = styled.button<PaletteItemProps>`
  all: unset;
  display: block;
  width: 64px;
  height: 74px;
  background: url('${props => props.value}');
  border-left: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  border-right: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  cursor: pointer;

  &:first-child {
    border-left: 0 solid #fff;
  }

  &:last-child {
    border-left: 0 solid #fff;
  }
`;

export const StickerPaletteItem = styled.button<PaletteItemProps>`
  all: unset;
  display: block;
  width: 64px;
  height: 74px;
  background: url('${props => props.value}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-left: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  border-right: ${props => (props.separated ? '2px solid #fff' : '0 solid #fff')};
  cursor: pointer;

  &:first-child {
    border-left: 0 solid #fff;
  }

  &:last-child {
    border-left: 0 solid #fff;
  }
`;
