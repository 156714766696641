import { Palette, PatternCategory, StickerCategory } from './models';

export const colors: Palette[] = [
  [
    [45, 58, 100],
    [171, 111, 76],
    [215, 83, 90],
    [241, 160, 120],
    [255, 251, 146]
  ],

  [
    [42, 21, 31],
    [92, 78, 99],
    [168, 174, 196],
    [137, 152, 173],
    [88, 103, 119]
  ],

  [
    [44, 29, 71],
    [123, 175, 189],
    [250, 235, 235],
    [230, 158, 157],
    [72, 107, 83]
  ],

  [
    [129, 38, 27],
    [178, 76, 71],
    [228, 216, 194],
    [160, 139, 127],
    [110, 88, 82]
  ],

  [
    [32, 31, 32],
    [236, 80, 40],
    [237, 173, 145],
    [250, 240, 223],
    [106, 176, 190]
  ],

  [
    [138, 176, 148],
    [125, 125, 171],
    [162, 179, 196],
    [52, 88, 119],
    [163, 182, 154]
  ],

  [
    [144, 155, 172],
    [200, 191, 210],
    [232, 212, 215],
    [251, 234, 249],
    [222, 226, 253]
  ]

  // ,[
  //   [132, 173, 216],
  //   [246, 193, 163],
  //   [253, 237, 159],
  //   [252, 244, 203],
  //   [189, 243, 188]
  // ],
  //
  // [
  //   [132, 154, 202],
  //   [155, 183, 206],
  //   [192, 223, 211],
  //   [218, 232, 223],
  //   [228, 196, 211]
  // ],
  //
  // [
  //   [95, 39, 234],
  //   [146, 45, 232],
  //   [192, 50, 200],
  //   [222, 51, 134],
  //   [239, 154, 239]
  // ],
  //
  // [
  //   [242, 177, 175],
  //   [255, 254, 190],
  //   [176, 243, 258],
  //   [167, 195, 249],
  //   [246, 201, 251]
  // ],
  //
  // [
  //   [235, 154, 84],
  //   [242, 185, 108],
  //   [246, 204, 211],
  //   [248, 227, 114],
  //   [251, 242, 118]
  // ],
  //
  // [
  //   [236, 105, 143],
  //   [240, 157, 182],
  //   [242, 177, 195],
  //   [245, 198, 213],
  //   [244, 222, 224]
  // ],
  //
  // [
  //   [30, 60, 83],
  //   [68, 111, 160],
  //   [108, 162, 211],
  //   [149, 209, 242],
  //   [164, 227, 244]
  // ],
  //
  // [
  //   [91, 127, 109],
  //   [114, 143, 129],
  //   [170, 194, 179],
  //   [207, 225, 222],
  //   [235, 244, 244]
  // ],
  //
  // [
  //   [125, 104, 126],
  //   [172, 137, 169],
  //   [211, 158, 191],
  //   [205, 170, 194],
  //   [206, 187, 202]
  // ],
  //
  // [
  //   [56, 62, 64],
  //   [56, 80, 82],
  //   [84, 129, 133],
  //   [133, 159, 159],
  //   [182, 211, 212]
  // ],
  //
  // [
  //   [225, 133, 130],
  //   [231, 155, 145],
  //   [238, 176, 160],
  //   [243, 197, 174],
  //   [250, 218, 188]
  // ],
  //
  // [
  //   [68, 62, 115],
  //   [70, 56, 176],
  //   [98, 85, 174],
  //   [107, 93, 204],
  //   [104, 91, 179]
  // ],
  //
  // [
  //   [112, 20, 30],
  //   [171, 53, 69],
  //   [231, 60, 80],
  //   [216, 93, 112],
  //   [221, 139, 154]
  // ],
  //
  // [
  //   [92, 21, 67],
  //   [180, 42, 128],
  //   [235, 63, 175],
  //   [227, 71, 176],
  //   [214, 98, 178]
  // ]
];

// @ts-ignore
export const patterns: {
  [key in PatternCategory]: [string, string, string, string, string][];
} = {
  business: [],
  children: [],
  fashion: [],
  food: []
};

for (const category of ['business', 'children', 'fashion', 'food']) {
  let max = 1;

  switch (category) {
    case 'business':
      max = 4;
      break;
    case 'children':
    case 'fashion':
      max = 3;
      break;
    case 'food':
      max = 2;
      break;
  }

  for (let i = 1; i <= max; i++) {
    const palette: any = [];
    for (let k = 1; k <= 5; k++) {
      palette[k - 1] = require(`./assets/patterns/${category}/${i}-${k}.png`).default;
    }
    patterns[category as 'business' | 'children' | 'fashion' | 'food'].push(palette);
  }
}

// @ts-ignore
export const stickers: {
  [key in StickerCategory]: [string, string, string, string, string, string, string, string][];
} = {
  business: [],
  children: [],
  fashion: [],
  food: []
};

for (const category of ['business', 'children', 'fashion', 'food']) {
  for (let i = 1; i <= 3; i++) {
    const collection: any = [];
    for (let k = 1; k <= 8; k++) {
      collection[k - 1] = `https://pa-generator.pages.dev/stickers/${category}/${i}-${k}.png`;
    }
    stickers[category as 'business' | 'children' | 'fashion' | 'food'].push(collection);
  }
}
