import React from 'react';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { LogoEditor } from './LogoEditor';
import { BackgroundEditor } from './BackgroundEditor';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: #f5f5f8;
`;

export const App = () => {
  return (
    <RecoilRoot>
      <Container>
        <Router>
          <Switch>
            <Route path='/logo' component={LogoEditor} />
            <Route path='/background' component={BackgroundEditor} />
            <Route path='/'>
              <div>
                <Link to='/logo'>Logo Generator</Link>
                <Link to='/background'>Background Generator</Link>
              </div>
            </Route>
          </Switch>
        </Router>
      </Container>
    </RecoilRoot>
  );
};
