import { atom, selector } from 'recoil';
import { colors } from './constants';

export type Color = [number, number, number];

export type LogoStep = 'name' | 'shapes' | 'category' | 'results';
export type BackgroundStep = 'home' | 'type' | 'category' | 'palette';
export type BackgroundType = 'pattern' | 'sticker' | undefined;
export type Shape = undefined | 'free' | 'custom';
export type Category = undefined | 'food' | 'banking' | 'sport' | 'hackathon';
export type PatternCategory = 'children' | 'fashion' | 'business' | 'food' | 'geometric';
export type StickerCategory = 'children' | 'fashion' | 'business' | 'food';
export type Palette = [Color, Color, Color, Color, Color];
export type ApplicationView = undefined | 'logo-generator' | 'background-generator';

export const logoStep = atom<LogoStep>({
  key: 'logoStep',
  default: 'name'
});

export const backgroundStep = atom<BackgroundStep>({
  key: 'backgroundStep',
  default: 'home'
});

export const getPageTitle = selector({
  key: 'logoPageTitle',
  get: ({ get }) => {
    switch (get(logoStep)) {
      case 'name':
        return 'Company name';
      case 'shapes':
        return 'Choose Your Logo Style';
      default:
        return undefined;
    }
  }
});

export const companyName = atom<string>({
  key: 'logoName',
  default: ''
});

export const isCompanyNameValid = selector({
  key: 'isLogoNameValid',
  get: ({ get }) => {
    const name = get(companyName);
    return /^[a-z0-9_-]+$/i.test(name);
  }
});

export const shape = atom<Shape>({
  key: 'logoShapesType',
  default: undefined
});

export const category = atom<Category>({
  key: 'logoCategory',
  default: undefined
});

export const backgroundType = atom<BackgroundType>({
  key: 'backgroundType',
  default: undefined
});

export const palette = atom<number>({
  key: 'selectedPalette',
  default: 0
});

export const patternPalette = atom<number>({
  key: 'selectedPatternPalette',
  default: 0
});

export const stickerPalette = atom<number>({
  key: 'selectedStickerPalette',
  default: 0
});

// [palette, color in it]
export const color = atom<[number, number]>({
  key: 'selectedColor',
  default: [0, 0]
});

export const patternCategory = atom<PatternCategory | undefined>({
  key: 'patternCategory',
  default: undefined
});

export const stickerCategory = atom<StickerCategory | undefined>({
  key: 'stickerCategory',
  default: undefined
});

export const applicationView = atom<ApplicationView>({
  key: 'applicationView',
  default: undefined,
})
