import { useRecoilState, useRecoilValue } from 'recoil';
import { palette, PatternCategory, patternCategory, patternPalette } from '../models';
import styled from 'styled-components';
import { ColorPaletteItem, Palette, PatternPaletteItem } from '../components/Palette';
import { colors, patterns } from '../constants';
import { useAsyncFn } from 'react-use';
import { getPatternBg } from '../utils/api';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  width: 360px;
  height: calc(100vh - 80px);
  flex-direction: column;
`;

const Content = styled.div`
  width: calc(100vw - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 790px;
  gap: 20px;
`;

const ContentItem = styled.div<{ url: string }>`
  width: 244px;
  height: 210px;
  background: #ffffff;
  border-radius: 16px;
  background-image: url('${props => props.url}');
  background-size: cover;
`;

const SidebarNote = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #545869;
  margin-bottom: 12px;
  margin-left: 4px;
`;

const RandomButton = styled.button<{ disabled?: boolean }>`
  background: ${props => (props.disabled ? '#DCDDE9' : '#545869')};
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  transition: background-color ease-in 0.2s;
  padding: 16px 40px;

  &:hover {
    background: ${props => (props.disabled ? '#DCDDE9' : '#282b36')};
  }
`;

const FetchButton = styled.button<{ disabled?: boolean }>`
  background: ${props => (props.disabled ? '#DCDDE9' : '#3a76e8')};
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  transition: background-color ease-in 0.2s;
  padding: 16px 48px;

  &:hover {
    background: ${props => (props.disabled ? '#DCDDE9' : '#386cd0')};
  }
`;

const getRandomInt = (min: number, max: number): number => {
  return min + Math.round((max - min) * Math.random());
};

export const Palettes = () => {
  const [selectedPalette, setSelectedPalette] = useRecoilState(palette);
  const [selectedPatterns, setSelectedPatterns] = useRecoilState(patternPalette);
  const selectedCategory = useRecoilValue(patternCategory);

  const [state, doFetch] = useAsyncFn(async () => {
    if (!selectedCategory) {
      return;
    }
    return getPatternBg(selectedCategory, selectedPatterns + 1, colors[selectedPalette]);
  }, [selectedPalette, selectedPatterns]);

  const onRansomSelect = () => {
    setSelectedPalette(getRandomInt(0, 2));
    setSelectedPatterns(getRandomInt(0, 3));
  };

  let colorPaletteLen = 1;

  switch (selectedCategory) {
    case 'business':
      colorPaletteLen = 3;
      break;
    case 'children':
    case 'fashion':
      colorPaletteLen = 4;
      break;
    case 'food':
      colorPaletteLen = 5;
      break;
  }

  if (window.innerHeight < 861) {
    colorPaletteLen--;
  }

  // @ts-ignore
  return (
    <Wrapper>
      <Sidebar>
        <SidebarNote>Patterns</SidebarNote>
        {patterns[selectedCategory as PatternCategory].map((palette, p) => (
          <Palette key={p} selected={selectedPatterns === p}>
            {palette.map((url, c) => (
              <PatternPaletteItem key={c} value={url} onClick={() => setSelectedPatterns(p)} />
            ))}
          </Palette>
        ))}
        <br />
        <SidebarNote>Color Palettes</SidebarNote>
        {colors
          .filter((_, i) => i < colorPaletteLen)
          .map((palette, p) => (
            <Palette key={p} selected={selectedPalette === p}>
              {palette.map((color, c) => (
                <ColorPaletteItem key={c} value={color} onClick={() => setSelectedPalette(p)} />
              ))}
            </Palette>
          ))}
        <br />
        <ButtonWrapper>
          <RandomButton onClick={onRansomSelect}>Random</RandomButton>
          <FetchButton onClick={doFetch}>Generate</FetchButton>
        </ButtonWrapper>
      </Sidebar>
      <Content>
        {state.loading && 'loading...'}
        {state.value && (
          <Grid>
            {!state.loading &&
              state.value &&
              state.value.map((item, i) => <ContentItem key={i} url={item} />)}
          </Grid>
        )}
      </Content>
    </Wrapper>
  );
};
