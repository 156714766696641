import { Card, CardsWrapper } from '../components/Card';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  patternCategory,
  stickerCategory,
  PatternCategory,
  StickerCategory,
  backgroundType
} from '../models';
import maskGroup1 from '../assets/patterns/Mask Group-1.png';
import maskGroup2 from '../assets/patterns/Mask Group-2.png';
import maskGroup3 from '../assets/patterns/Mask Group-3.png';
import maskGroup4 from '../assets/patterns/Mask Group 4.png';

export const CategorySelection = () => {
  const [selectedPatternCategory, setPatternCategory] = useRecoilState(patternCategory);
  const [selectedStickerCategory, setStickerCategory] = useRecoilState(stickerCategory);
  const bgType = useRecoilValue(backgroundType);

  const createHandleClick = (category: PatternCategory | StickerCategory) => () => {
    if (bgType === 'pattern') {
      setPatternCategory(category);
    } else {
      setStickerCategory(category as StickerCategory);
    }
  };

  const isSelected = (category: PatternCategory | StickerCategory) => {
    if (bgType === 'sticker') {
      return selectedStickerCategory === category;
    }

    return selectedPatternCategory === category;
  };

  return (
    <CardsWrapper>
      <Card
        selected={isSelected('children')}
        onClick={createHandleClick('children')}
        image={maskGroup1}
        title="Children"
      />
      <Card
        selected={isSelected('fashion')}
        onClick={createHandleClick('fashion')}
        image={maskGroup2}
        title="Fashion"
      />
      <Card
        selected={isSelected('business')}
        onClick={createHandleClick('business')}
        image={maskGroup3}
        title="Business"
      />
      <Card
        selected={isSelected('food')}
        onClick={createHandleClick('food')}
        image={maskGroup4}
        title="Food"
      />
    </CardsWrapper>
  );
};
