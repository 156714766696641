import { useRecoilState, useRecoilValue } from 'recoil';
import { palette, StickerCategory, stickerCategory, stickerPalette } from '../models';
import styled from 'styled-components';
import { ColorPaletteItem, Palette, StickerPaletteItem } from '../components/Palette';
import { colors, stickers } from '../constants';
import { useAsync, useAsyncFn } from 'react-use';
import { getArrangeStickers } from '../utils/api';
import { FC } from 'react';

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  width: 360px;
  height: calc(100vh - 80px);
  flex-direction: column;
`;

const Content = styled.div`
  width: calc(100vw - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 790px;
  gap: 20px;
`;

const SvgContentItem = styled.div<{ bg: string }>`
  position: relative;
  width: 244px;
  height: 210px;
  background: ${props => props.bg};
  border-radius: 16px;
  overflow: hidden;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SidebarNote = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #545869;
  margin-bottom: 12px;
  margin-left: 4px;
`;

const RandomButton = styled.button<{ disabled?: boolean }>`
  background: ${props => (props.disabled ? '#DCDDE9' : '#545869')};
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  transition: background-color ease-in 0.2s;
  padding: 16px 40px;

  &:hover {
    background: ${props => (props.disabled ? '#DCDDE9' : '#282b36')};
  }
`;

const FetchButton = styled.button<{ disabled?: boolean }>`
  background: ${props => (props.disabled ? '#DCDDE9' : '#3a76e8')};
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  transition: background-color ease-in 0.2s;
  padding: 16px 48px;

  &:hover {
    background: ${props => (props.disabled ? '#DCDDE9' : '#386cd0')};
  }
`;

const getRandomInt = (min: number, max: number): number => {
  return min + Math.round((max - min) * Math.random());
};

const AsyncItem: FC<{ data: Promise<string>; bg: string }> = ({ data, bg }) => {
  const state: any = useAsync(async () => data, [data]);

  return state.loading && !state.value ? null : (
    <SvgContentItem dangerouslySetInnerHTML={{ __html: state.value }} bg={bg} />
  );
};

export const StickerArrangement = () => {
  const [selectedPalette, setSelectedPalette] = useRecoilState(palette);
  const [selectedStickers, setSelectedStickers] = useRecoilState(stickerPalette);
  const selectedCategory = useRecoilValue(stickerCategory);

  const [state, doFetch] = useAsyncFn(async () => {
    if (!selectedCategory) {
      return;
    }

    return getArrangeStickers(selectedCategory, selectedStickers);
  }, [selectedPalette, selectedStickers]);

  const onRansomSelect = () => {
    setSelectedPalette(getRandomInt(0, 2));
    setSelectedStickers(getRandomInt(0, 3));
  };

  // @ts-ignore
  return (
    <Wrapper>
      <Sidebar>
        <SidebarNote>Stickers</SidebarNote>
        {stickers[selectedCategory as StickerCategory].map((palette, p) => (
          <Palette key={p} selected={selectedStickers === p}>
            {palette
              .filter((_, i) => i < 5)
              .map((url, c) => (
                <StickerPaletteItem key={c} value={url} onClick={() => setSelectedStickers(p)} />
              ))}
          </Palette>
        ))}
        <br />
        <SidebarNote>Color Palettes</SidebarNote>
        {colors
          .filter((_, i) => i < (window.innerHeight > 860 ? 4 : 3))
          .map((palette, p) => (
            <Palette key={p} selected={selectedPalette === p}>
              {palette.map((color, c) => (
                <ColorPaletteItem key={c} value={color} onClick={() => setSelectedPalette(p)} />
              ))}
            </Palette>
          ))}
        <br />
        <ButtonWrapper>
          <RandomButton onClick={onRansomSelect}>Random</RandomButton>
          <FetchButton onClick={doFetch}>Generate</FetchButton>
        </ButtonWrapper>
      </Sidebar>
      <Content>
        <Grid>
          {state.loading && <div>loading...</div>}
          {!state.loading &&
            state.value &&
            state.value.map((item, i) => {
              const color = colors[selectedPalette][i % colors[selectedPalette].length].join(',');
              const bg = `rgb(${color})`;
              return <AsyncItem data={item} bg={bg} />;
            })}
        </Grid>
      </Content>
    </Wrapper>
  );
};
