import styled from 'styled-components';

export const Input = styled.input`
  font-family: 'Montserrat', sans-serif;
  max-width: 730px;
  margin: 1em auto;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 28px 22px;
  width: 100%;
  height: 84px;
  -webkit-appearance: none;
  outline: none;
  box-shadow: 0px 0px 34px rgba(168, 168, 168, 0.11);
  &::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #0008;
    opacity: 0.5;
  }
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #0008;

  &::placeholder {
    color: #000;
    opacity: 0.5;
    text-align: left;
  }
`;
